
const HOST = "api.web.nedrex.net"
const PROTOCOL = "https"
export const CONTEXT_PATH=""
export const HOST_URL = PROTOCOL+"://" + HOST
export const SOCKET_URL = PROTOCOL+"://" + HOST
export const PATH_PREFIX= ""
export const STATIC_PATH="/static/"

// export const NEDREX_API=PROTOCOL+"://api.nedrex.net/open"

