import { render, staticRenderFns } from "./Quick.vue?vue&type=template&id=27a94236&scoped=true"
import script from "./Quick.vue?vue&type=script&lang=js"
export * from "./Quick.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a94236",
  null
  
)

export default component.exports